import { TRegionKey } from '@source/interface';
import {
  NEXT_PUBLIC_GOOGLE_SCRIPT_URL,
  NEXT_PUBLIC_WHOLESALE_URL,
  NEXT_PUBLIC_PROMOTION_GOOGLE_SHEET_KEY_MY,
} from '@source/constants/env';

interface APIProps {
  listings: string;
  tickets: string;
  otp: string;
  scheduleViewing: string;
  scheduleViewingBufferTime: string;
  buyHunt: string;
  buyNow: string;
  buyNew: string;
  requestOTP: string;
  verifyOTP: string;
  configOptions: string;
  config: string;
  lineChat: string;
  notifyMe: string;
  contacts: string;
  logOut: string;
  viewingHistory: string;
  contactUs: string;
  loginByOTT: string;
  verifyOTT: string;
  searchFilterHistory: string;
  inventories: string;
  timeSlot: string;
  regionPathUrl: string;
  dataQuery: string;
  paymentQuery: string;
  captainGo: string;
  workshopTickets: string;
  usedCarLoan: string;
  rabbitUrl: string;
}
export const WHOLESALE_URL = NEXT_PUBLIC_WHOLESALE_URL;

export const BASE_CONTACT_URL = `${NEXT_PUBLIC_WHOLESALE_URL}/api/v1/rabbit/contacts`;

/** Config API URLS */
export const API_URL = (region?: TRegionKey): APIProps => {
  const url = NEXT_PUBLIC_WHOLESALE_URL;
  const regionPathUrl = `${NEXT_PUBLIC_WHOLESALE_URL}/api/v1/rabbit/${region}`;
  const rabbitUrl = `${NEXT_PUBLIC_WHOLESALE_URL}/api/v1/rabbit`;
  const captainUrl = `${NEXT_PUBLIC_WHOLESALE_URL}/api/v1/captain-go/rabbit/${region}`;

  /** CRAZY RABBIT API PATHS */
  return {
    listings: `${regionPathUrl}/listings`, // listing details data
    tickets: `${regionPathUrl}/sell-car/tickets`, // submit sell-request form
    otp: `${regionPathUrl}/contacts/otp`, // otp buy-only
    scheduleViewing: `${regionPathUrl}/viewing-schedule`,
    scheduleViewingBufferTime: `${regionPathUrl}/config/appointment-buffer-time`,
    buyHunt: `${regionPathUrl}/tickets/buy-hunt`,
    buyNow: `${regionPathUrl}/tickets`,
    buyNew: `${regionPathUrl}/tickets/buy_new_car`,
    requestOTP: `${regionPathUrl}/contacts/otp`,
    verifyOTP: `${regionPathUrl}/contacts/otp/verify`,
    contacts: `${regionPathUrl}/contacts`,
    logOut: `${rabbitUrl}/logout`,
    configOptions: `${regionPathUrl}/config/options`,
    config: `${regionPathUrl}/config`,
    lineChat: `${url}/api/v1/rabbit/listings`,
    notifyMe: `${regionPathUrl}/tickets/buy-hunt-notify`,
    viewingHistory: `${regionPathUrl}/listings/histories`,
    contactUs: `${regionPathUrl}/contact-us`,
    searchFilterHistory: `${regionPathUrl}/search/histories`,
    loginByOTT: `${url}/api/v1/login-contact-by-ott`,
    verifyOTT: `${url}/api/v1/rabbit/contacts/ott/verify`,
    inventories: `${regionPathUrl}/inventories`,
    timeSlot: `${regionPathUrl}/time-slot`,
    regionPathUrl: `${regionPathUrl}`,
    dataQuery: `${rabbitUrl}/data-query`,
    paymentQuery: `${captainUrl}`,
    captainGo: `${url}/api/v1/captain-go`,
    workshopTickets: `${regionPathUrl}/tickets/workshop`,
    usedCarLoan: `${regionPathUrl}/used-car-loan`,
    rabbitUrl
  };
};

export const CAR_DETAILS_INCLUDES = [
  'status',
  'inventory.inspections.generated_reports',
  'inspection.generated_reports',
  'default_images',
  'engine_audio',
  '360_interior_photos',
  '360_exterior_photos',
  '360_exterior_close_up_photos',
  'mechanic_video_notes',
  'inspection_summary',
  'inventory.inspections.inspector',
  'inspection.inspector',
  'inspection.inspection_defect_images',
  'registration_area',
  'location',
  'authorised_dealer_report',
  'vehicle_evaluation_report',
  'accident_history_report',
  'inside_badges',
];

export const GOOGLE_SCRIPT_EXEC_SHEET_URL = `${NEXT_PUBLIC_GOOGLE_SCRIPT_URL}/macros/s/${NEXT_PUBLIC_PROMOTION_GOOGLE_SHEET_KEY_MY}/exec`;

export default {};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import Appointment from '@source/services/Appointment';
import {
  IAppointmentBufferTime,
  IAppointmentItem,
  ICreateAppointmentProps,
  IGetAppointmentProps,
  TicketDetail,
  IGetTicketProps
} from '@source/interface/appointment';
import { ICountryProps, IErrorResponse, IRejectedValue, ISuccessResponse } from '@source/interface/apiResponse';

const sliceName = 'APPOINTMENT';

// Todo: Init first
interface IAppointmentStateProps {
  currentAppointment: IAppointmentItem | null;
  appointments: IAppointmentItem[] | null;
  appointmentBufferTime: number;
  loading: boolean | null;
  errors: string | null;
}

const INITIAL_STATE: IAppointmentStateProps = {
  currentAppointment: null,
  appointments: null,
  appointmentBufferTime: 0,
  loading: null,
  errors: null,
};

export const createAppointment = createAsyncThunk<
  ISuccessResponse<IAppointmentItem>,
  ICreateAppointmentProps,
  IRejectedValue
>(
  `${sliceName}/getTimeSlots`,
  async ({ country, ticketSlug, payload }: ICreateAppointmentProps, { rejectWithValue }) => {
    try {
      const res = await Appointment.createAppointment(country, ticketSlug, payload);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const listAppointment = createAsyncThunk<ISuccessResponse<IAppointmentItem[]>, ICountryProps, IRejectedValue>(
  `${sliceName}/listAppointment`,
  async ({ country }: ICountryProps, { rejectWithValue }) => {
    try {
      const res = await Appointment.listAppointment(country);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return rejectWithValue(error.response.data);
    }
  },
);

export const getAppointment = createAsyncThunk<
  ISuccessResponse<IAppointmentItem>,
  IGetAppointmentProps,
  IRejectedValue
>(`${sliceName}/getAppointment`, async ({ country, appointmentId }: IGetAppointmentProps, { rejectWithValue }) => {
  try {
    const res = await Appointment.getAppointment(country, appointmentId);
    return res.data;
  } catch (err: any) {
    // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    const error: AxiosError<IErrorResponse> = err; // cast the error for access
    if (!error.response) throw err;
    return rejectWithValue(error.response.data);
  }
});

export const getAppointmentBufferTime = createAsyncThunk<ISuccessResponse<IAppointmentBufferTime>, ICountryProps>(
  `${sliceName}/getAppointmentBufferTime`,
  async ({ country }: ICountryProps) => {
    try {
      const res = await Appointment.getAppointmentBufferTime(country);
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return {};
    }
  },
);

export const getTicketDetail = createAsyncThunk<ISuccessResponse<TicketDetail>, IGetTicketProps>(
  `${sliceName}/getTicketDetail`,
  async ({ payload, country}: IGetTicketProps) => {
    try {
      const res = await Appointment.getTicketDetail({payload, country});
      return res.data;
    } catch (err: any) {
      // Catch clause variable type annotation must be 'any' or 'unknown' if specified.
      const error: AxiosError<IErrorResponse> = err; // cast the error for access
      if (!error.response) throw err;
      return {};
    }
  },
);

const slice = createSlice({
  name: sliceName,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAppointment.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(createAppointment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currentAppointment = { ...payload?.data };
    });
    builder.addCase(createAppointment.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });
    builder.addCase(listAppointment.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(listAppointment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.appointments = payload?.data;
    });
    builder.addCase(listAppointment.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });
    builder.addCase(getAppointment.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getAppointment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.currentAppointment = payload?.data;
    });
    builder.addCase(getAppointment.rejected, (state, action) => {
      state.loading = false;
      let error = null;
      if (action.payload) {
        error = typeof action.payload.errors !== 'string' ? action.payload.message : action.payload.errors;
      } else {
        error = action.error.message;
      }
      state.errors = error || null;
    });
    builder.addCase(getAppointmentBufferTime.pending, (state) => {
      state.loading = true;
      state.errors = null;
    });
    builder.addCase(getAppointmentBufferTime.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.appointmentBufferTime = payload?.data.buffer_time_in_hour;
    });
    builder.addCase(getAppointmentBufferTime.rejected, (state) => {
      state.loading = false;
      state.appointmentBufferTime = 0;
    });
  },
});

// eslint-disable-next-line no-empty-pattern
const {} = slice.actions;
export {};

export default slice.reducer;

import axios from 'axios';
import { get } from 'lodash/fp';
// import qs from 'qs';

import { API_URL } from '@source/constants/api';
import { TRegionKey } from '@source/interface';
import { ICreateAppointmentPayload, IGetTicketProps } from '@source/interface/appointment';

import { WHOLESALE_AXIOS_USER_AUTH } from './UserProfile';

export default {
  async getBySlug(country: TRegionKey, slug: string) {
    const url = `${API_URL(country).regionPathUrl}/tickets/${slug}`;
    const data = await WHOLESALE_AXIOS_USER_AUTH.get(url);
    return get(['data', 'data'])(data) as Record<string, any>;
  },
  createAppointment: (country: TRegionKey, ticketSlug: string, payload: ICreateAppointmentPayload) => {
    const createAppointmentAPI = `${API_URL(country).regionPathUrl}/ticket/${ticketSlug}/inspection-appointment`;
    // const createAppointmentAPI = `http://localhost:8000/ticket/${ticketSlug}/inspection-appointment?country=${country}`;

    return WHOLESALE_AXIOS_USER_AUTH.post(`${createAppointmentAPI}`, payload);
  },

  listAppointment: (country: TRegionKey) => {
    const appointmentListAPI = `${API_URL(country).regionPathUrl}/inspection-appointment`;
    // const appointmentListAPI = `http://localhost:8000/inspection-appointments?country=${country}`;

    return WHOLESALE_AXIOS_USER_AUTH.get(appointmentListAPI);
  },

  getAppointment: (country: TRegionKey, appointmentId: number) => {
    const appointmentDetailAPI = `${API_URL(country).regionPathUrl}/inspection-appointment/${appointmentId}`;
    // const appointmentDetailAPI = `http://localhost:8000/inspection-appointment/${appointmentId}`;

    return WHOLESALE_AXIOS_USER_AUTH.get(`${appointmentDetailAPI}`);
  },

  getAppointmentBufferTime: (country: TRegionKey) => {
    const appointmentBufferTime = `${API_URL(country).scheduleViewingBufferTime}`;
    return axios.get(appointmentBufferTime);
  },

  getTicketDetail: ({payload, country}: IGetTicketProps) => {
    const { rabbitUrl } = API_URL();
    return WHOLESALE_AXIOS_USER_AUTH.post(`${rabbitUrl}/${country}/data-query/tickets`, payload);
  },
};
